.read-more {
  font-size: 16px;
  line-height: 1.5;

  @include media-breakpoint-up(xl) {
    font-size: 20px;
  }

  &:hover {
    .arrow-pseudo {
      @extend .arrow-pseudo--move--right;
    }
  }
}
