.button {
  font-size: 1rem;
  border-radius: 70px;
  border-width: 1px;
  border-style: solid;
  padding: 10px 20px;
  display: inline-block;
  transition: all 0.2s ease;

  &--cta-black {
    &,
    &:visited {
      border-color: $button-cta-black;
      background-color: $button-cta-black;
      color: $white;

      .dark-background & {
        color: $white;
        border-color: $white;
      }
    }

    &:hover {
      border-color: $button-cta-black;
      background-color: $white;
      color: $button-cta-black;

      .dark-background & {
        color: $off-white;
        border-color: $off-white;
      }
    }

    &:active {
      border-color: $dark;
      background-color: $dark;
      color: $white;

      .dark-background & {
        color: $white;
        border-color: $white;
      }
    }

    &:focus {
      box-shadow: $input-focus-box-shadow;
    }
  }

  &--cta-white {
    &,
    &:visited {
      border-color: $button-cta-black;
      background-color: transparent;
      color: $button-cta-black;

      .dark-background & {
        color: $white;
        border-color: $white;
      }
    }

    &:hover {
      border-color: $button-cta-black;
      background-color: $button-cta-black;
      color: $white;

      .dark-background & {
        color: $black;
        background-color: $white;
        border-color: $white;
      }
    }

    &:active {
      border-color: $dark;
      background-color: transparent;
      color: $dark;

      .dark-background & {
        color: $white;
        border-color: $white;
      }
    }

    &:focus {
      box-shadow: $input-focus-box-shadow;
    }
  }

  &[disabled] {
    border-color: $grey;
    background-color: $grey;
    color: $white;
  }
}
