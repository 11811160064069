.block--v3--feature-text {
  $prefix: &;

  &--text {
    @include fluid-type(26px, 40px);
    line-height: 1.15em;
  }

  &--link {
    @include ease;
    font-size: 16px;

    @include media-breakpoint-up(lg) {
      font-size: 20px;
    }
  }
}
