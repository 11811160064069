.block--link-tiles {
  &,
  &.margin-default {
    margin: 0 0 $block-margin-mobile-default - calc($grid-gutter-width / 2);

    @include media-breakpoint-up(md) {
      margin: 0 0 $block-margin-default - $grid-gutter-width;
    }
  }

  &.margin-tall {
    margin: 0 0 $block-margin-mobile-tall - calc($grid-gutter-width / 2);

    @include media-breakpoint-up(md) {
      margin: 0 0 $block-margin-tall - $grid-gutter-width;
    }
  }

  &.margin-short {
    margin: 0 0 $block-margin-mobile-short - calc($grid-gutter-width / 2);

    @include media-breakpoint-up(md) {
      margin: 0 0 $block-margin-short - $grid-gutter-width;
    }
  }

  .container-fluid {
    @include media-breakpoint-down(sm) {
      padding: 0;
    }

    > .row {
      @include media-breakpoint-down(sm) {
        margin: 0;
      }

      > div {
        @include media-breakpoint-down(sm) {
          padding: 0;
        }
      }
    }
  }

  &--tile {
    position: relative;

    &--image {
      width: 100%;
    }

    .thumb-video {
      min-height: 100px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 99;
      padding: 1.5em;
      @include media-breakpoint-up(md) {
        left: calc($grid-gutter-width / 2);
        right: calc($grid-gutter-width / 2);
      }

      .see-more-work {
        position: absolute;
        bottom: 0.5em;
        left: $grid-gutter-width;
      }
    }

    &--info {
      position: relative;
      padding: calc($grid-gutter-width / 3) calc($grid-gutter-width / 2);
      flex-direction: column;
      align-items: flex-start;

      @include ease;

      @include media-breakpoint-up(md) {
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: calc($grid-gutter-width / 2);
        right: calc($grid-gutter-width / 2);
        opacity: 0;

        &:hover {
          opacity: 1;
        }
      }
    }

    &--title {
      margin-bottom: 0;
    }

    &--arrow {
      float: right;
      position: absolute;
      right: calc($grid-gutter-width / 2);
      top: calc($grid-gutter-width / 2);

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &--desc {
      margin-top: 0.4em;
      margin-bottom: 0;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &--view {
      margin-top: 0.5em;
      margin-bottom: 0;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &--more {
      a {
        display: block;
        height: 100%;
      }

      &--video {
        height: 100%;
      }
    }
  }
}
