.block--v3--link-stack {
  &--heading {
    @include fluid-type(16px, 27px);

    font-weight: 400;
    line-height: 1.4em;
  }

  &--link-list {
    padding: 0;
    margin: 0;
  }

  &--list-item {
    list-style: none;
    margin-bottom: 1.5em;

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      @include ease;

      path {
        stroke: $black;

        .dark-background & {
          stroke: $white;
        }
      }
    }

    &:hover svg {
      margin-top: 0.3em;
    }
  }
}
