.block--services-grid {
  &,
  &.margin-default {
    margin: 0 0 $block-margin-mobile-default - calc($grid-gutter-width / 2);

    @include media-breakpoint-up(md) {
      margin: 0 0 $block-margin-default - $grid-gutter-width;
    }
  }

  &.margin-tall {
    margin: 0 0 $block-margin-mobile-tall - calc($grid-gutter-width / 2);

    @include media-breakpoint-up(md) {
      margin: 0 0 $block-margin-tall - $grid-gutter-width;
    }
  }

  &.margin-short {
    margin: 0 0 $block-margin-mobile-short - calc($grid-gutter-width / 2);

    @include media-breakpoint-up(md) {
      margin: 0 0 $block-margin-short - $grid-gutter-width;
    }
  }

  &--title h2 {
    @include fluid-type(20px, 30px);
  }

  &--item {
    &--head {
      margin-bottom: 10px;
    }

    &--image {
      width: 30px;
      height: 30px;
      margin-right: 8px;
    }

    &--title {
      font-size: 20px;
      line-height: 1.29;
      color: $text-black;
      text-decoration: underline;
    }
  }
}
