.block--v3--work-landing-featured-work--tile {
  $tile: &;
  position: relative;
  margin-bottom: 40px;

  @include media-breakpoint-up(md) {
    margin-bottom: 80px;
  }

  &--image {
    width: 100%;
  }

  .thumb-video {
    min-height: 100px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 1.5em;

    @include media-breakpoint-up(md) {
      left: calc($grid-gutter-width / 2);
      right: calc($grid-gutter-width / 2);
    }

    .see-more-work {
      position: absolute;
      bottom: 0.5em;
      left: $grid-gutter-width;
    }
  }

  &--arrow.arrow-pseudo {
    flex: 0 0 16px;
    margin-top: 9px;

    @include media-breakpoint-up(md) {
      margin-top: 14px;
    }
  }

  &--info {
    position: relative;
    padding-top: 16px;
    flex-direction: column;
    align-items: flex-start;

    @include media-breakpoint-up(md) {
      padding-top: 40px;
    }
  }

  &--title {
    margin-bottom: 0;
    font-size: 22px;

    @include media-breakpoint-up(md) {
      font-size: 26px;
    }
  }

  &--desc {
    margin-top: 4px;

    @include media-breakpoint-up(md) {
      margin-top: 7px;
    }

    &,
    a {
      color: $text-grey;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.5;

      @include media-breakpoint-up(md) {
        font-size: 14px;
      }
    }
  }

  &--image {
    @include ease;
  }

  &--link {
    &:hover {
      #{$tile}--arrow {
        @extend .arrow-pseudo--move--rotate-to-right;
      }

      &--image {
        transform: translate(0px, -5px);
      }
    }
  }
}
