.share-social {
  &--heading {
    font-size: 23px;
  }

  &--icons {
    padding: 0;
    list-style: none;
  }

  &--icon {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }

}