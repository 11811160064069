.block--text-statement-cta {
  $blockClass: &;
  &--title {
    line-height: 1.31;
    color: $text-black;
    margin-bottom: 50px;

    .dark-background & {
      color: $white;
    }

    h2 {
      &,
      &.font-normal {
        @include fluid-type(20px, 30px);
      }

      &.font-large {
        @include fluid-type(27px, 48px);
      }
    }
  }

  &--title,
  &--text--row,
  &--button {
    #{$blockClass}.text-center & {
      @include media-breakpoint-up(md) {
        padding: 0 14%;
      }
    }
    #{$blockClass}.text-left & {
      @include media-breakpoint-up(md) {
        padding: 0 28% 0 0;
      }
    }
    #{$blockClass}.text-right & {
      @include media-breakpoint-up(md) {
        padding: 0 0 0 28%;
      }
    }
  }
}
