.block--v3--people-stack {
  margin-bottom: 50px;

  &--title h2 {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 50px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  &--item {
    padding: 24px 0;
    border-top: 1px solid $social-border-grey;
    position: relative;
    cursor: pointer;

    &:hover {
      border-top-color: $black;
    }

    .reverse {
      flex-flow: column-reverse;

      @include media-breakpoint-up(lg) {
        flex-flow: column;
      }
    }

    &--name {
      @include fluid-type(16px, 20px);
      font-weight: 400;
    }

    &--job-position p {
      margin-bottom: 0;
    }

    &--job-position,
    &--country {
      font-size: 16px;
      color: #949494;
    }

  }

  &--tile {
    transition: opacity 0.25s ease;
    display: none;
    opacity: 0;
    max-width: 50%;

    @include media-breakpoint-up(md) {
      max-width: 325px;
    }

    @include media-breakpoint-up(lg) {
      top: 30vh;
    }

    &--description {
      font-size: 14px;
      margin-top: 30px;

      @include media-breakpoint-up(sm) {
        font-size: 16px;
      }
    }

    &--image {
      width: 328px;
      height: 315px;
      object-fit: cover;

      @include media-breakpoint-down(sm) {
        width: 210px;
        height: 200px;
      }

      @include media-breakpoint-up(lg) {
        margin: 0;
      }
    }

    &.show {
      display: block;
      opacity: 1;
      margin-top: 30px;
    }
  }

  &--display {
    width: 100%;
    height: 100%;
    position: relative;
  }

  &--display-wrapper {
    height: 100vh;
  }

  &--item:hover hr {
    background-color: $black;
  }
}

.fade-in {
  animation: fadeIn 1;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
