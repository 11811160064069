.block--v3--office-map {
  &--heading-mobile {
    @extend h3;
  }

  &--address {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;

    @include media-breakpoint-up(lg) {
      font-size: 16px;
    }
  }

  &--map {
    height: 160px;

    @include media-breakpoint-up(sm) {
      height: 250px;
    }

    @include media-breakpoint-up(md) {
      height: 600px;
    }

    @include media-breakpoint-up(lg) {
      height: 833px;
    }
  }
}
