.block--v3--statistics {
  margin-bottom: 120px;

  @include media-breakpoint-down(sm) {
    :last-child {
      margin-top: 40px;
    }
  }

  &--figure {
    font-size: 48px;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 1.2;
  }

  &--description {
    font-size: 20px;
  }
}
