form {
  > div {
    position: relative;
  }

  input,
  textarea {
    width: 100%;
    border: 1px solid $black-30;
    color: $black;
    background: none;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    padding: px-to-rem(16px) px-to-rem(20px);
    outline: 0;

    &::placeholder {
      color: $black-30;
    }

    .dark-background & {
      border: 1px solid $white-30;
      color: $white;

      &::placeholder {
        color: $white-30;
      }
    }
  }

  label {
    color: $dark;
    width: 100%;
    margin-bottom: 0.625em;
    font-size: px-to-rem(14px);

    @include media-breakpoint-up(md) {
      font-size: px-to-rem(16px);
    }

    .dark-background & {
      color: $white;
    }
  }

  .error {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    font-size: px-to-rem(12px);
    text-align: right;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"] {
    transition: box-shadow .5s;
    box-shadow: $input-focus-box-shadow-default;

    &:focus {
      box-shadow: $input-focus-box-shadow;
    }
  }

  textarea {
    display: block;
    border: 1px solid $black-30;
    resize: vertical;
    padding: 0.76em 1em;

    &:focus {
      box-shadow: $input-focus-box-shadow;
    }

    + label {
      left: 1em;
    }
  }
}
