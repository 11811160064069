.block--v3--contact-form {
  &--title {
    @include fluid-type(22px, 40px);
    line-height: 1.2;
  }

  &--hero {
    color: $text-grey;
    font-size: px-to-rem(14px);

    @include media-breakpoint-up(md) {
      font-size: px-to-rem(16px);
    }
  }

  .contact-button {
    cursor: pointer;
    outline: 0;
    text-transform: none;
    font-size: px-to-rem(16px);
    font-weight: 400;
    letter-spacing: -0.016em;
    color: $dark;
    display: flex;
    align-items: center;
    padding: 10px 20px;

    .arrow-pseudo {
      margin-left: 0.5em;
    }

    &:hover .arrow-pseudo {
      transform: translateX(0.5em);
    }
  }

  form .text-area {
    min-height: 110px;
  }
}
