.entry-type-homepage-3-0 .block--v3--prefooter-links {
  &--item {
    border-top-color: $divider-grey;
  }

  &--item-wrapper {
    &:nth-child(2) .block--v3--prefooter-links--item {
      border-top: 1px solid $divider-grey;
    }
  }
}
