.block--v3--services-columns {
  &--heading {
    @include fluid-type(22px, 26px);
    margin-bottom: 2px;

    @include media-breakpoint-up(md) {
      margin-bottom: 4px;
    }
  }

  &--text {
    color: $text-grey;
    font-size: 14px;
    line-height: 1.3;
    min-height: 3em;

    @include media-breakpoint-up(md) {
      font-size: 16px;
    }

  }

  &--service {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.24px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $social-border-grey;

    &:hover {
      .arrow-pseudo {
        @extend .arrow-pseudo--move--nudge-left;
      }
    }
  }
}
