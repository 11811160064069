.block--v3--about-title {

  &--title {
    @include fluid-type(36px, 60px);
  }

  &--aboutBody p {
    font-size: 16px;
    line-height: 1.5;
    color: $about-text-grey;

    @include media-breakpoint-up(xl) {
      font-size: 20px;
    }
  }

  .read-more {
    color: $black;
  }
}
