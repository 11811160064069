.pagination-item {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.15;
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
  color: $grey;

  @include media-breakpoint-up(lg) {
    font-size: 26px;
  }

  &--first,
  &--prev,
  &--next,
  &--last {
    font-size: 40px;
  }

  &--page {
    display: none;

    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }

  &--current {
    color: $dark;

    &,
    &:hover {
      color: $dark;
    }
  }

  &--arrow {
    margin-top: 6px;
  }

  .arrow-pseudo {
    border-right-color: $grey !important;

    &::before {
      border-right-color: $grey;
      border-top-color: $grey;
    }

    &::after {
      border-top-color: $grey;
    }
  }

  &:hover {
    opacity: 0.6;

    .arrow-pseudo {
      border-right-color: $dark !important;

      &::before {
        border-right-color: $dark;
        border-top-color: $dark;
      }

      &::after {
        border-top-color: $dark;
      }
    }
  }
}
