.block--about-grid {
  &,
  &.margin-default {
    margin: 0 0 $block-margin-mobile-default - $about-grid-bottom-margin-mobile;

    @include media-breakpoint-up(md) {
      margin: 0 0 $block-margin-default - $about-grid-bottom-margin;
    }
  }

  &.margin-tall {
    margin: 0 0 $block-margin-mobile-tall - $about-grid-bottom-margin-mobile;

    @include media-breakpoint-up(md) {
      margin: 0 0 $block-margin-tall - $about-grid-bottom-margin;
    }
  }

  &.margin-short {
    margin: 0 0 $block-margin-mobile-short - $about-grid-bottom-margin-mobile;

    @include media-breakpoint-up(md) {
      margin: 0 0 $block-margin-short - $about-grid-bottom-margin;
    }
  }

  &--item {
    margin-bottom: $about-grid-bottom-margin-mobile;

    @include media-breakpoint-up(md) {
      margin-bottom: $about-grid-bottom-margin;
    }

    &--info {
      cursor: pointer;
    }

    &--name {
      @include fluid-type(17.5px, 20.9px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: calc($grid-gutter-width / 4);
      margin-bottom: 0.25em;
    }

    &--media {
    }

    &--title {
      margin-bottom: 0;
    }

    &--desc {
      max-height: 0;
      overflow: hidden;
      @include ease;
    }
  }
}
