* {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
}

// @FONT-FACES –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
@font-face {
  font-family: "Matter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/theme/fonts/MatterTRIAL-Light.woff2) format("woff2"),
    url(/theme/fonts/MatterTRIAL-Light.woff) format("woff");
}

@font-face {
  font-family: "Matter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/theme/fonts/MatterTRIAL-Regular.woff2) format("woff2"),
    url(/theme/fonts/MatterTRIAL-Regular.woff) format("woff");
}

@font-face {
  font-family: "Matter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/theme/fonts/MatterTRIAL-Medium.woff2) format("woff2"),
    url(/theme/fonts/MatterTRIAL-Medium.woff) format("woff");
}

@font-face {
  font-family: "Matter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/theme/fonts/MatterTRIAL-SemiBold.woff2) format("woff2"),
    url(/theme/fonts/MatterTRIAL-SemiBold.woff) format("woff");
}

// –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––

body {
  color: $dark;
  font-family: "Matter", sans-serif;
  font-weight: 300;
  font-size: $base-font-size;
  line-height: 1.4em;

  &.dark-background {
    color: $white;
  }
}

.hero {
  @include fluid-type(45px, 75px);
  font-weight: 400;
  line-height: 1.05em;
  letter-spacing: -0.01em;
}

h1,
.h1 {
  @include fluid-type(32px, 55px);
  font-weight: 400;
  font-style: normal;
  line-height: 1.2em;
}

h2,
.h2 {
  @include fluid-type(30px, 48px);
  font-weight: 400;
  font-style: normal;
  line-height: 1.3em;
}

h3,
.h3,
h4,
.h4 {
  @include fluid-type(20px, 30px);
  font-style: normal;
  font-weight: 400;
  line-height: 1.4em;
  margin-bottom: 0.6em;
}

h5,
.h5,
h6,
.h6 {
  @include fluid-type(17px, 22px);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

p,
.p {
  margin-bottom: 0.75em;
}

// LINKS –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––

.nav-link {
  // @include fluid-type(30px, 44px);
  font-weight: 400;
  // line-height: 1.2em;
}

.cta {
  border-bottom: solid 2px;
  padding-bottom: 0.25em;
  @include ease;
  &:hover {
    padding-bottom: 0.5em;
  }
}

a {
  text-decoration: none;
  color: inherit;
  &:hover {
    color: inherit;
  }
}

p a {
  // @extend .underline;
}

.white-text {
  color: $white;

  &:hover {
    color: $off-white;
    text-decoration: underline;
  }

  &:active {
    color: $grey;
  }

  &:focus {
    color: $highlight-blue;
  }
}

// LISTS ––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––

ul.no-style {
  padding: 0;
  li {
    list-style: none;
  }
}

// HELPERS –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––

.underline {
  border-bottom: solid 3px;
  padding-bottom: 0.1em;
  line-height: 1.5em;
}
