.block--twocol-image-textlist-cta {
  &--item {
    &--image {
      width: 38px;
      height: 38px;
    }

    &--title {
      @include fluid-type(18px, 21px);
    }
  }

  &--left {
    width: 48px;
    flex: 0 0 48px;
    padding-right: 10px;
    box-sizing: content-box;
  }
}
