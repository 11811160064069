@mixin ease {
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

@mixin ease-in {
  -webkit-transition: all 0.25s ease-in;
  -moz-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
  -ms-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
}

@mixin ease-in-out {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

@mixin ease-out {
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

@mixin ease-slow {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

@mixin ease-fast {
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.ease {
  @include ease;
}
