.block--hero-banner {
  margin-top: 0;
  color: $white;

  h2 {
    @include fluid-type(28px, 60px);
  }

  &--background-image {
    width: 100vw;
    height: calc(100vw * 362 / 375);

    @include media-breakpoint-up(sm) {
      height: 90vh;
      min-height: 400px;
      max-height: 858px;
    }
  }
}