.block--social-media {
  &--image {
    width: 16px;
    height: 16px;
    margin-right: 12px;
  }

  &--name {
    font-weight: 400;
  }
}
