.block--v3--end-cta {
  border-top: 1px solid $color-gainsboro;
  border-bottom: 1px solid $color-gainsboro;
  padding-top: 8.5rem;
  padding-bottom: 6.9rem;

  @include media-breakpoint-up(md) {
    padding-top: 9.3rem;
    padding-bottom: 9.3rem;
  }

  .dark-background & {
    border-top-color: $white;
    border-bottom-color: $white;
  }

  &--heading h2 {
    @include fluid-type(20px, 30px);
  }

  &--title,
  &--button {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}
