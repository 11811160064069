.block--end-cta {
  &--title h2 {
    @include fluid-type(20px, 30px);
  }

  &--title,
  &--button {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}