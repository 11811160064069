.block--v3--prefooter-links {
  $prefix: &;
  @include ease;

  &--item-wrapper {
    &:nth-child(2) #{$prefix}--item {
      border-top: 1px solid $social-border-grey;
    }
  }

  &--item {
    padding-top: 36px;
    padding-bottom: 36px;

    @include media-breakpoint-up(md) {
      border-top: 1px solid $social-border-grey;
      padding-top: 44px;
      padding-bottom: 44px;
    }

  }

  &--image {
    @include ease-in-out;

    flex: 0 0 103px;

    @include media-breakpoint-up(md) {
      flex: 0 0 165px;
    }

    @include media-breakpoint-up(lg) {
      flex: 0 0 205px;
    }
  }

  &--content {
    flex: 1 1 calc(100% - 103px);

    @include media-breakpoint-up(md) {
      flex: 1 1 calc(100% - 165px);
    }

    @include media-breakpoint-up(lg) {
      flex: 1 1 calc(100% - 205px);
    }
  }

  &--item-heading {
    @include fluid-type(22px, 26px);
    font-weight: 400;
    font-style: normal;
    line-height: 1.2em;
  }

  &--cta {
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5em;
  }

  &--cta {
    position: relative;

    &--arrow {
      transition-delay: 0.1s;
      width: 10px;
      padding-bottom: 3px;
    }
  }

  &--item-link:hover {
    #{$prefix}--image {
      @include jump-hover;
    }

    #{$prefix}--cta--arrow {
      @extend .arrow-pseudo--move--right;
    }
  }
}
