.block--v3--services-stack {
  $prefix: &;

  &--item {
    border-top: 1px solid $divider-grey;
  }

  &--item-heading {
    @include fluid-type(30px, 60px);
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -0.015em;
  }

  &--item-link {
    padding-top: 0.41em;
    margin-bottom: 0.4em;

    &:hover {
      #{$prefix}--arrow {
        margin-right: 0.5em;
      }
    }
  }

  &--arrow {
    @include ease;
  }

  &--item-links {
    padding-bottom: 1.56em;
    padding-right: 65px;

    &--link {
      @include underline-hover;

      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $text-grey-light;
    }
  }
}
