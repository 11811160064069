.block--v3--twocol-headings-and-text {
  &--heading {
    @include fluid-type(26px, 40px);

    margin-bottom: 32px;
  }

  &--text {
    line-height: 1.5;
    font-size: 16px;

    @include media-breakpoint-up(md) {
      font-size: 20px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}
