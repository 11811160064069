.block--v3--text-section-with-image-cta {
  &--heading {
    @extend .block--v3--text-section--heading;
  }

  &--large-text {
    @extend .block--v3--text-section--large-text;
  }

  &--text {
    @extend .block--v3--text-section--text;
  }

  &--cta-caption {
    line-height: 1.4em;
    font-size: 20px;
  }
}
