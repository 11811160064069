@mixin zoom-hover {
  transition: transform 0.5s;
  /* Animation */

  &:hover {
    transform: scale(1.1);
  }
}

.zoom-hover {
  @include zoom-hover;
}
