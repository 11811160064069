.block--general {
  &,
  &.margin-default {
    margin: 0 0 $block-margin-mobile-default;

    @include media-breakpoint-up(md) {
      margin: 0 0 $block-margin-default;
    }
  }

  &.margin-tall {
    margin: 0 0 $block-margin-mobile-tall;

    @include media-breakpoint-up(md) {
      margin: 0 0 $block-margin-tall;
    }
  }

  &.margin-short {
    margin: 0 0 $block-margin-mobile-short;

    @include media-breakpoint-up(md) {
      margin: 0 0 $block-margin-short;
    }
  }

  &.border-default {
    border-top: 1px solid $color-gainsboro;
    border-bottom: 1px solid $color-gainsboro;
    padding-top: 8.5rem;
    padding-bottom: 6.9rem;

    @include media-breakpoint-up(md) {
      padding-top: 9.3rem;
      padding-bottom: 9.3rem;
    }

    .dark-background & {
      border-top-color: $white;
      border-bottom-color: $white;
    }
  }
}
