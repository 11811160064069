.block--v3--carousel-big-tile {
  $prefix: &;
  width: 100%;
  overflow: hidden;

  &--carousel-container {
    .row {
      @include media-breakpoint-down(md) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .container-fluid,
    .col-12 {
      @include media-breakpoint-down(md) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

  }

  &--heading {
    @include fluid-type(20px, 30px);
    margin-bottom: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4em;
    margin-bottom: 0.6em;
  }

  &--image {
    @include ease;
    @include zoom-hover;

    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &--link {
      cursor: none;
      display: inline-block;
      overflow: hidden;
    }
  }

  &--arrow-container {
    padding: 0 0.2em 0.1em 0;
    margin-top: 8px;
  }

  &--arrow {
    height: 12px;
    width: 12px;
  }

  &--text {
    padding-top: 20px;

    @include media-breakpoint-up(lg) {
      padding-top: 24px;
    }

    &-bottom {
      padding-bottom: 3px;
    }

    &--link:hover {
      #{$prefix}--arrow {
        @extend .arrow-pseudo--move--rotate-to-right;
      }
    }
  }

  &--title {
    font-weight: 400;
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: -0.015em;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
      margin-right: 35px;
    }
  }

  &--service-link {
    @include ease;
    @include underline-hover;

    font-weight: 400;
    font-size: 12px;
    line-height: 1.2;
    color: $text-grey-light;

    &:hover {
      color: $white;
    }
  }

  &--drag-arrow.arrow-pseudo {
    margin: 0 0 0 5px;
  }

  .has-cursor-text {
    position: relative;

    &:hover {
      cursor: none;

      .cursor-text {
        opacity: 0;

        @include media-breakpoint-up(lg) {
          opacity: 1;
        }
      }

      #{$prefix}--text {
        cursor: auto;
      }
    }
  }

  .cursor-text {
    transition: opacity 0.25s ease;
    background-color: $black;
    color: $white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 82px;
    height: 82px;
    border-radius: 50%;
    z-index: 100;
    pointer-events: none;
    opacity: 0;
    position: fixed;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    font-size: 15px;

    .arrow-pseudo::before {
      border-top-color: $white !important;
      border-right-color: $white !important;
    }

    .arrow-pseudo::after {
      border-top-color: $white !important;
    }

    .dark-background & {
      background-color: $cursor-blue;

      .arrow-pseudo::before {
        border-top-color: $white !important;
        border-right-color: $white !important;
      }

      .arrow-pseudo::after {
        border-top-color: $white !important;
      }
    }
  }

  @media (pointer:coarse) {
    .cursor-text {
      position: absolute;
      top: 48% !important;
      right: 0 !important;
      left: auto !important;
      transform: translate(-53px, -50%);
      opacity: 0;

      @include media-breakpoint-up(lg) {
        transform: translate(0, -50%);
        opacity: 1;
      }
    }
  }

  .js-carousel-dragging + .cursor-text {
    opacity: 0 !important;
  }
}
