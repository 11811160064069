.accordion-toggle {
  $prefix: &;
  display: block;
  cursor: pointer;

  &-icon {
    @include accordion-toggle-small-base;
    @include accordion-toggle-small-plus;
    @include ease;
    opacity: 0.5;

    #{$prefix}:hover & {
      opacity: 1;
    }
  }

  &[aria-expanded="true"] #{$prefix}-icon {
    @include accordion-toggle-small-minus;
  }
}
