.block--v3--contact-offices {
  &--address {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;

    @include media-breakpoint-up(lg) {
      font-size: 16px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &--left {
    border-top: 1px solid $divider-grey;
  }

  &--details-side,
  &--map-side {
    @include media-breakpoint-up(md) {
      margin-bottom: 80px;
    }
  }

  &--map {
    height: 160px;

    @include media-breakpoint-up(sm) {
      height: 280px;
    }

    @include media-breakpoint-up(md) {
      height: 260px;
    }

    @include media-breakpoint-up(lg) {
      height: 430px;
    }
  }

  &--heading,
  &--clock {
    font-size: 18px;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
      font-size: 26px;
    }
  }

  &--link--arrow {
    width: 10px;

    @include media-breakpoint-up(md) {
      width: 16px;
      margin-bottom: 4px;
    }
  }
}
