.block--v3--blog-tile {
  $tile: &;

  &--title {
    h3 {
      @include fluid-type(18px, 20px);
    }
  }

  &--desc {
    color: $text-grey;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5
  }

  &--image {
    @include ease;
    @include zoom-hover;

    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &--container {
      overflow: hidden;
    }
  }
}
