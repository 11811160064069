.block--related-services-buttons {
  &--heading {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 15px;
  }

  &--small-button {
    margin-right: 7px;
    margin-bottom: 15px;
  }
}
