.block--v3--showreel {
  &--toggle-icon {
    display: inline-block;
    width: 12px;
    height: 12px;
    position: relative;
    margin-left: 5px;
  }

  .collapse-container {
    overflow: hidden;
  }

  .collapse:not(.show) {
    display: block;

    .collapse-container {
      height: 0;
    }
  }

  .collapse.show {
    display: block;

    .collapse-container {
      height: 100%;
    }
  }
}
