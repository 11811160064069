@mixin underline-hover {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 0;
    height: 1px;
    background-color: $text-black;
    transition: width 0.25s ease-out;

    .dark-background & {
      background-color: $white;
    }
  }

  &:hover {
    &::before {
      width: 100%;
    }
  }
}
