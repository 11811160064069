.block--v3--office-title {
  padding-top: 140px;

  @include media-breakpoint-up(lg) {
    padding-top: 180px;
    margin-bottom: 30px;
  }

  &--clock {
    @extend h1;
  }

  &--hero-text {
    @include fluid-type(30px, 48px);
    line-height: 1.2;
    color: $text-grey;
    font-weight: 300;

    p:last-child {
      margin-bottom: 0;
    }
  }

  &--image {
    display: block;

    @include media-breakpoint-up(md) {
      padding-bottom: 10px;
    }
  }
}
