.block--video-banner-playable {
  video {
    width: 100%;
  }

  &--video {
    @include ease;
    width: 100%;

    &.visible {
      opacity: 1;
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  .full-width-on-mobile {
    @include media-breakpoint-down(sm) {
      width: 100vw;
      max-width: none;
      padding: 0;
    }
  }
}
