.site-switcher {
  background: $dark;
  color: $white;
  padding: 10px;
  font-size: 10px;
  position: fixed;
  top: 0;
  left: auto;
  right: auto;
  z-index: 5000;

  ul {
    @extend .no-style;
    margin-bottom: 0;
  }

  li.current {
    font-weight: 600;

    &::after {
      content: "«";
    }
  }

  a {
    @extend .white-text;
  }
}