.animate__animated.animate__fadeIn {
  --animate-duration: 1.0s;
  --animate-delay: 0.9s;
}

.split-fadeInUp {
  opacity: 0;

  .char {
    opacity: 0;
    -webkit-transform: translate(0px, 0.1em);
    transform: translate(0px, 0.1em);
    transition: transform .2s cubic-bezier(.3, 0, .3, 1), opacity .3s linear;
    transition-delay: calc(5ms * var(--char-index));

  }

  &[data-scroll="in"] {
    opacity: 1;

    .char {
      opacity: 1;
      -webkit-transform: translate(0px, 0);
      transform: translate(0px, 0);
    }
  }
}

.scroll-fadeIn:not(.animate__animated) {
  opacity: 0;
}
