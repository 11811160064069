.block--v3--related-blogs {
  &--title {
    h3 {
      margin-bottom: 30px;
    }
  }
}

.block--v3--related-blog-tile {
  $prefix: &;

  &--title {
    h3 {
      @include fluid-type(20px, 28px);
    }
  }

  &--image {
    @include ease-in-out;
  }

  &--link:hover {
    #{$prefix}--image {
      @include jump-hover;
    }
  }
}
