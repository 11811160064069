.block--related-blogs-tiles {
  &--heading {
    @include fluid-type(20px, 30px);
  }


  .blog-related-blogs {
    border-top: 1px solid $grey;

    &--title {
      h3 {
        margin-bottom: 30px;
      }
    }
  }

  .blog-post-tile {
    &--title {
      h3 {
        @include fluid-type(20px, 28px);
        @include ease;
      }

      &:hover h3 {
        color: $highlight-blue;
      }
    }

    &--more {
      a {
        @include ease;
        text-decoration: none;
        position: relative;
        padding-right: 26px;

        &::before,
        &::after {
          @include ease;
          position: absolute;
          content: '';
          background-image: url(/theme/images/icon-right-arrow.svg);
          background-size: 16px 16px;
          width: 16px;
          height: 16px;
          display: inline-block;
          top: 5px;
          right: 0;
          margin-right: 5px;

        }

        &::before {
          background-image: url(/theme/images/icon-right-arrow.svg);
          opacity: 1;
        }

        &::after {
          background-image: url(/theme/images/icon-right-arrow-hover.svg);
          opacity: 0;
        }

        &:hover {

          &::before,
          &::after {
            margin-right: 0;
          }

          &::before {
            opacity: 0;
          }

          &::after {
            opacity: 1;
          }
        }
      }
    }

    &--image--container {
      position: relative;

      a {
        &:after {
          @include ease;

          @include media-breakpoint-up(md) {
            content: '';
            background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 0px;
            left: 0px;
            opacity: 0;
            pointer-events: none;
            z-index: 100;
            width: 100%;
            height: 100%;
          }
        }

        &:hover:after {
          @include media-breakpoint-up(md) {
            opacity: 1;
          }
        }
      }

    }
  }

}
