.arrow-pseudo {
  display: block;
  position: relative;
  width: 13px;
  height: 11px;
  margin-right: 5px;
  margin-bottom: 2px;

  &::before,
  &::after {
    content: "";
    position: absolute;
  }

  &::before {
    top: 50%;
    left: 0;
    border-top: 1px solid $black;
    border-right: 1px solid $black;
    height: 8px;
    width: 8px;
    transform: translateX(100%) translateY(-63%) rotate(45deg);
    transform-origin: top left;

    .dark-background & {
      border-top-color: $white;
      border-right-color: $white;
    }
  }

  &::after {
    top: 50%;
    left: 0;
    border-top: 1px solid $black;
    height: 1px;
    width: 12px;
    transform: translateY(-12%);
    transform-origin: top left;

    .dark-background & {
      border-top-color: $white;
    }
  }

  // Sizes

  &.arrow-pseudo--small {
    width: 10px;
    margin-bottom: 1px;

    &::before {
      height: 7px;
      width: 7px;
    }

    &::after {
      width: 10px;
    }
  }

  &.arrow-pseudo--medium {
    width: 16px;
    height: 16px;
    margin-bottom: 3px;

    &::before {
      height: 10px;
      width: 10px;
      top: 45%;
    }

    &::after {
      width: 16px;
      top: 45%;
    }

    &.arrow-pseudo--end {
      border-right: 1px solid $black;
      padding-right: 4px;
      width: 16px;
      width: 20px;

      &::before {
        top: 45%;
      }

      &::after {
        top: 45%;
      }
    }
  }

  &.arrow-pseudo--large {
    width: .65em;
    height: .56em;

    &::before {
      height: 18px;
      width: 18px;
      transform: translateX(85%) translateY(-64%) rotate(45deg);
    }

    &::after {
      height: 1px;
      width: 25px;
    }
  }

  &.arrow-pseudo--x-large {
    width: .65em;
    height: .56em;

    &::before {
      height: 24px;
      width: 24px;
      transform: translateX(85%) translateY(-64%) rotate(45deg);
    }

    &::after {
      height: 1px;
      width: 33px;
    }
  }

  // Thickness

  &.arrow-pseudo--semibold {
    &::before {
      border-top-width: 2px;
      border-right-width: 2px;
    }

    &::after {
      border-top-width: 2px;
    }

    &.arrow-pseudo--end {
      border-right-width: 2px;
    }
  }

  &.arrow-pseudo--bold {
    &::before {
      border-top-width: 3px;
      border-right-width: 3px;
    }

    &::after {
      border-top-width: 3px;
    }

    &.arrow-pseudo--end {
      border-right-width: 3px;
    }
  }

  &.arrow-pseudo--extra-bold {
    &::before {
      border-top-width: 5px;
      border-right-width: 5px;
    }

    &::after {
      border-top-width: 5px;
    }
  }

  // Colours

  &.arrow-pseudo--white {
    &::before {
      border-top-color: $white;
      border-right-color: $white;
    }

    &::after {
      border-top-color: $white;
    }
  }

  // Directions

  &.arrow-pseudo--up {
    transform: rotate(-90deg);
    margin-bottom: 3px;
  }

  &.arrow-pseudo--down {
    transform: rotate(90deg);
    margin-top: 3px;

    &::after {
      top: 53%;
    }
  }

  &.arrow-pseudo--left {
    transform: rotate(180deg);
  }

  &.arrow-pseudo--down-right {
    transform: rotate(45deg);
  }

  // Hover
  &--move {
    @include ease-out;

    &--right {
      transform: translateX(0.5em);
    }

    &--rotate-to-right {
      transform: rotate(0);
    }

    &--nudge-down {
      transform: rotate(90deg) translateX(0.3em);
    }

    &--nudge-left {
      transform: translateX(-0.3em);
    }
  }
}
