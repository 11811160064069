//- Mixin: Fluid Type
// Magic calc + vh to allow text to be fluid between minimum and maximum breakpoints.
// @site https://madebymike.com.au/writing/precise-control-responsive-typography/

@mixin fluid-type($min-font-size: 1px,
  $max-font-size: 100px,
  $lower-range: 320px,
  $upper-range: $container-fluid-max-width
){
  font-size: calc(
    #{$min-font-size} + #{(
        calc($max-font-size / ($max-font-size * 0 + 1px)) -
          calc($min-font-size / ($min-font-size * 0 + 1px))
      )} * ((100vw - #{$lower-range}) / #{(
            calc($upper-range / ($upper-range * 0 + 1px)) -
              calc($lower-range / ($lower-range * 0 + 1px))
          )})
  );
  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range) {
    font-size: $max-font-size;
  }
}
