@mixin accordion-toggle-base {
  width: 1.25rem;
  height: 1.25rem;

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: $black;
    @include ease;

    .dark-background & {
      background-color: $white;
    }
  }

  &::before {
    width: 1.25rem;
    height: 0.125rem;
    top: 0.55rem;
    right: 0;
  }

  &::after {
    width: 0.125rem;
    height: 1.25rem;
    top: 0;
    right: 0.55rem;
  }
}

@mixin accordion-toggle-plus {
  &::before {
    opacity: 1;
  }

  &::after {
    opacity: 1;
  }
}

@mixin accordion-toggle-minus {
  &::after {
    opacity: 0;
  }
}

@mixin accordion-toggle-small-base {
  width: 12px;
  height: 12px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: $black;
    @include ease;

    .dark-background & {
      background-color: $white;
    }
  }

  &::before {
    width: 12px;
    height: 1px;
    top: 5.5px;
    right: 0;

    @include media-breakpoint-up(md) {
      height: 2px;
      top: 5px;
    }
  }

  &::after {
    width: 1px;
    height: 12px;
    top: 0;
    right: 5.5px;

    @include media-breakpoint-up(md) {
      width: 2px;
      right: 5px;
    }
  }
}

@mixin accordion-toggle-small-plus {
  &::before {
    opacity: 1;
  }

  &::after {
    opacity: 1;
  }
}

@mixin accordion-toggle-small-minus {
  &::after {
    opacity: 0;
  }
}
