body {
  background-color: $white;
  color: $text-black;

  &.background-change {
    transition: background-color 0.25s ease;

    @include media-breakpoint-down(md) {
      background-color: $white !important;
    }
  }
}

a {
  color: $dark;

  .dark-background & {
    color: $white;
  }
}

// STUFF  ––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// GRID  ––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
.container-fluid {
  max-width: $container-fluid-max-width;
  padding-left: calc($spacer / 2);
  padding-right: calc($spacer / 2);

  @include media-breakpoint-up(sm) {
    padding-left: $spacer;
    padding-right: $spacer;
  }

  @include media-breakpoint-up(xl) {
    padding-left: calc($spacer * 2);
    padding-right: calc($spacer * 2);
  }

  @include media-breakpoint-up(xxl) {
    padding-left: calc($spacer * 4);
    padding-right: calc($spacer * 4);
  }
}

//––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––

video {
  width: 100% !important;
  height: auto !important;
}

.embed-responsive iframe {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  left: -1px;
  top: -1px;
}

.img-fluid {
  width: 100%;
}

.img-max-fluid {
  max-width: 100%;
  height: auto;
}

/**
 * Background visibility
 */
.bg-sm-up-none {
  @include media-breakpoint-up(sm) {
    background: none !important;
  }
}

.bg-sm-down-none {
  @include media-breakpoint-down(xs) {
    background: none !important;
  }
}

/**
 * image will cover the full container.
 */
.image-full-cover {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.social--link {
  @include ease;
  width: 45px;
  height: 45px;
  border: 1px solid $social-border-grey;
  border-radius: 50%;
  flex: 0 0 45px;

  &:active,
  &:hover,
  &:focus {
    border-color: $white;
  }
}
