.block--v3--link-grid {
  &--heading {
    font-weight: 400;
    line-height: 1.4em;
    font-size: 21px;
  }

  &--description {
    font-weight: 300;
    font-size: 17px;
    line-height: 1.53;
    color: $link-grid-description;
  }

  &--item {
    border-top: 1px solid $link-grid-border;
    margin-bottom: 1.5em;
    position: relative;
  }

  &--link--arrow {
    @include ease;
    margin-right: 0;

    a:hover & {
      margin-right: 5px;
    }

  }
}
