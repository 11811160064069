.block--service-info-tiles {
  $blockClass: &;

  background-color: $color-white-smoke;

  &--title h2 {
    color: $text-black;
  }

  &--tile {
    margin-bottom: 1.25rem;

    &.tile-left {
      @include media-breakpoint-up(md) {
        padding-right: 0.5rem;
      }
    }

    &.tile-right {
      @include media-breakpoint-up(md) {
        padding-left: 0.5rem;
      }
    }

    a {
      color: $text-black;

      &:hover,
      &:active {
        color: $white;
      }
    }

    &--link {
      @include ease;

      background-color: $white;
      border-radius: 0.625rem;
      padding: 1.4rem;

      @include media-breakpoint-up(md) {
        padding: 2.8rem;
      }

      &:hover,
      &:active {
        background-color: $black;
      }
    }

    &--wrapper {
      height: 10rem;

      @include media-breakpoint-up(md) {
        height: 17.5rem;
      }
    }

    &--title {
      @include ease-fast;

      @include fluid-type(20px, 27px);
      flex: 0 0 calc(100% - 5.6rem);

      @include media-breakpoint-up(md) {
        flex: 0 0 calc(100% - 6.8rem);
      }
    }

    &--desc {
      @include ease-fast;

      margin-bottom: 0;
      height: 2.35rem;
      flex: 0 0 4.35rem;

      @include media-breakpoint-up(md) {
        height: 3.55rem;
        flex: 0 0 5.55rem;
      }
    }

    &--more {
      @include ease-fast;

      margin-bottom: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.4;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      height: 1.25rem;
      flex: 0 0 1.25rem;
    }
  }
}
