.block--image-text-cta {
  &--large-title h2 {
    @include fluid-type(20px, 30px);
  }

  &--title {
    @include fluid-type(18px, 21.5px);
    margin-bottom: 1em;
  }

  &--text {
    padding: 0 14%;
    font-weight: 300;
  }
}