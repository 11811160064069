.block--twocol-image-text-cta {
  &--title h2 {
    @include fluid-type(20px, 30px);
  }

  &--title,
  &--text,
  &--button {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  &--text {
    h3, .h3, h4, .h4 {
      @include fluid-type(17px, 22px);
    }
  }
}