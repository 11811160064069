/*
  Colors
 */
$black: #000;
$dark: #111111;
$text-black: #2c2c2c;
$text-grey: #888;
$color-white-smoke: #f0f0f0;
$color-gainsboro: #d8d8d8;
$white: #fff;
$off-white: #bbb;
$grey: #aaa;
$dark-grey: #959595;
$highlight-blue: #68e2ff;
$button-cta-black: #2c2c2c;
$link-grid-border: #dfdfdf;
$link-grid-description: #6c6c6c;
$social-border-grey: #E4E4E4;
$text-grey: #949494;
$text-grey-light: #878787;
$cursor-blue: #0061FE;
$divider-grey: #414141;
$tile-grey: #272727;
$about-text-grey: #5F5F5F;
$form-outline-grey: #4E4E4E;
$form-label-grey: #4E4E4E;
$white-50: rgba(255, 255, 255, 0.5);
$black-50: rgba(0, 0, 0, 0.5);
$white-30: rgba(255, 255, 255, 0.3);
$black-30: rgba(0, 0, 0, 0.3);

/*
  Block Margins
 */
$block-margin-default: 120px;
$block-margin-tall: 180px;
$block-margin-short: 50px;
$block-margin-mobile-default: 60px;
$block-margin-mobile-tall: 90px;
$block-margin-mobile-short: 40px;
$hero-text-margin-adjust: 20px;
$contact-office-bottom-margin-adjust: 20px;
$about-grid-bottom-margin: 80px;
$about-grid-bottom-margin-mobile: 30px;

$container-fluid-max-width: 1800px;
$input-focus-box-shadow-default: 0px 3px 0px 0px transparent;
$input-focus-box-shadow: 0px 3px 0px 0px #999;

/*
  Bootstrap overrides
*/
$gray-500: #b6b6b6;
$gray-600: #686868;
$light: #f8f7f7;
$dark: #111111;
$spacer: 50px;
$spacers: (
);
$spacers: map-merge((0: 0,
      half: ($spacer * 0.25,
      ),
      1: ($spacer * 0.5,
      ),
      2: ($spacer * 1,
      ),
      3: ($spacer * 2,
      ),
      4: ($spacer * 3,
      ),
      5: ($spacer * 4,
      ),
      6: ($spacer * 6,
      ),
      7: ($spacer * 8,
      ),
    ),
    $spacers);
$link-hover-color: $link-color;
$link-hover-decoration: none;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1300px,
  xxl: 1800px,
  xxxl: 2100px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px,
);

$grid-gutter-width: $spacer;

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$text-muted: $gray-500;

$embed-responsive-aspect-ratios: (
  (21 9),
  (16 9),
  (4 3),
  (1 1),
  (3 2),
  (2 3),
  (3 4),
  (9 16)
);

$base-font-size: 16px;
