.block--v3--twocol-media {
  $block-class: &;

  .col-left {
    @include media-breakpoint-up(md) {
      padding-right: 8px;
    }

    #{$block-class}--media {
      margin-bottom: 20px;
    }
  }

  .col-right {
    @include media-breakpoint-up(md) {
      padding-left: 8px;
    }

    #{$block-class}--media {
      margin-bottom: 20px;
    }
  }
}
