.block--v3--leader-tiles {
  &--title {
    @include fluid-type(26px, 30px);
    font-weight: 400;
    line-height: 1.23;
  }

  &--leader {
    @include ease;
    margin-bottom: 40px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 80px;
    }
  }

  &--image-wrapper {
    @include ease;
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 21px;
    }
  }

  &--image img {
    @include ease-in-out;

    &:hover {
      @include jump-hover;
    }
  }

  &--name {
    @include ease;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -0.015em;
    font-size: 18px;
    margin-bottom: 6px;

    @include media-breakpoint-up(lg) {
      font-size: 20px;
      margin-bottom: 8px;
    }
  }

  &--job {
    @include ease;
    font-size: 14px;
    color: $text-grey;
    line-height: 1.2;
    letter-spacing: -0.015em;

    @include media-breakpoint-up(lg) {
      font-size: 16px;
    }
  }
}
