.play-button {
  background: rgba(30, 30, 30, 0.9);
  border-radius: 0.5em;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: 1em;
  margin-left: -3.25em;
  margin-top: -2em;
  width: 6.5em;
  height: 4em;
  color: $white;
  transition: opacity 250ms ease-out, background-color 40ms, color 40ms;
  opacity: 0;
  outline: none;
  border: none;

  &:hover {
    background-color: rgb(0, 173, 239);
  }

  .embed-responsive:hover & {
    opacity: 1;
  }

  &-wrapper {
    @include ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: 16;
    display: flex;
    align-items: flex-end;
    height: auto;
    justify-content: flex-end;
    font-size: 10px;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  .play-icon {
    margin: 0 auto;
    padding: 0.2em 0 0 0.2em;

    svg {
      width: 2em;
      height: 2em;
    }
  }

  .fill {
    fill: $white;
  }
}
