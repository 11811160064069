.block--v3--video-banner {
  video {
    width: 100%;
  }

  .full-width-on-mobile {
    @include media-breakpoint-down(sm) {
      width: 100vw;
      max-width: none;
      padding: 0;
    }
  }
}
