.block--v3--twocol-image-and-text {
  &--tile:not(:last-child) {
    padding-bottom: 60px;

    @include media-breakpoint-up(md) {
      padding-bottom: 80px;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 120px;
    }
  }

  &--image-tile {
    overflow: hidden;

    &--image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--text-tile {
    padding-top: 40px;

    @include media-breakpoint-up(lg) {
      padding-top: 0;
      padding-inline: 40px;
    }

    @include media-breakpoint-up(xl) {
      padding-inline: 90px;
    }

    &--text ul {
      padding-left: 20px;
    }

    &--heading {
      font-weight: 400;
      margin-bottom: 30px;

      @include media-breakpoint-up(md) {
        margin-bottom: 40px;
      }
    }

    .small-heading, .large-heading {
      line-height: 1.2em;
    }

    .small-heading {
      font-size: 24px;

      @include media-breakpoint-up(md) {
        font-size: 30px;
      }
    }

    .large-heading {
      font-size: 32px;

      @include media-breakpoint-up(md) {
        font-size: 40px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 48px;
      }
    }

    .small-text {
      font-size: 16px;
    }

    .large-text {
      font-size: 18px;

      @include media-breakpoint-up(xl) {
        font-size: 20px;
      }
    }
  }
}
