.block--v3--text-section {
  &--heading {
    font-weight: 600;
    line-height: 1.4em;
    @include fluid-type(16px, 27px);
  }

  &--large-text {
    line-height: 1.4em;
    @include fluid-type(16px, 27px);
  }

  &--text {
    line-height: 1.4em;
    @include fluid-type(14px, 18px);
  }
}
