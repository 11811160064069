.block--v3--large-image-and-link {
  position: relative;

  &--image {
    width: 100%;
    object-fit: cover;
    max-height: 900px;
    min-height: 300px;
  }

  &--container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: left;

    @include media-breakpoint-down(sm) {
      font-size: 32px!important;
    }
  }

  &--container-text {
    color: $dark-grey;
  }

  &--container-link {
    &--title {
      color: $white;
    }

    &:hover {
      .arrow-pseudo {
        @extend .arrow-pseudo--move--right;
      }
    }
  }

  &--arrow {
    transition-delay: 0.1s;
    padding-bottom: 3px;
  }

  .arrow-pseudo {
    &::before {
      border-top-color: $white !important;
      border-right-color: $white !important;
    }

    &::after {
      border-top-color: $white !important;
    }
  }
}
