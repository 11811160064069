.leader-profile {
  $prefix: &;

  &--container {
    max-width: 696px;
    padding: 0 40px;
  }

  &--image {
    margin-bottom: 80px;

    img {
      max-width: 100%;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 100px;
    }
  }

  &--title {
    font-size: 36px;
    font-weight: 400;

    @include media-breakpoint-up(md) {
      font-size: 60px;
    }
  }

  &--job-position {
    font-size: 22px;
    color: $text-grey;
    font-weight: 400;
    margin-bottom: 80px;

    @include media-breakpoint-up(md) {
      font-size: 26px;
      margin-bottom: 100px;
    }
  }

  &--description {
    font-size: 22px;
    line-height: 39px;
    margin-bottom: 80px;

    @include media-breakpoint-up(md) {
      font-size: 26px;
      margin-bottom: 100px;
    }
  }

  &--social-media {
    &--network {

      &:active,
      &:hover,
      &:focus {
        #{$prefix}--social-media--icon-wrapper {
          border-color: $white;
        }

        #{$prefix}--social-media--name &::before {
          width: 100%;
        }
      }
    }

    &--icon {
      background-color: transparent;
      background-position: center center;
      background-repeat: no-repeat;

      &.linkedin {
        width: 14px;
        height: 14px;
        background-image: url(/theme/images/social/social-linkedin.svg);

        .dark-background & {
          background-image: url(/theme/images/social/social-linkedin-white.svg);
        }
      }

      &.instagram {
        width: 24px;
        height: 24px;
        background-image: url(/theme/images/social/social-instagram.svg);

        .dark-background & {
          background-image: url(/theme/images/social/social-instagram-white.svg);
        }
      }

      &.twitter {
        width: 18px;
        height: 18px;
        background-image: url(/theme/images/social/social-twitter.svg);

        .dark-background & {
          background-image: url(/theme/images/social/social-twitter-white.svg);
        }
      }
    }

    &--icon-wrapper {
      @extend .social--link;

      margin-right: 17px;
    }

    &--name {
      @include underline-hover;
      font-weight: 400;
      font-size: 16px;
    }
  }

}
