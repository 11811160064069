.block--v3--accordion--item {
  padding-bottom: 16px;

  @include media-breakpoint-up(md) {
    padding-bottom: 26px;
  }

  &--heading {
    margin-bottom: 0;
    font-size: 16px;

    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  }

  a,
  p {
    color: $text-black;

    .dark-background & {
      color: $white;
    }
  }

  a {
    @include ease;

    &:hover {
      color: $text-black;
      opacity: 0.8;
    }

    .dark-background & {
      &:hover {
        color: $white;
      }
    }
  }

  &--toggle {
    border-top: 1px solid $social-border-grey;
    position: relative;
    padding-bottom: 0;
    padding-top: 16px;

    @include media-breakpoint-up(md) {
      padding-top: 28px;
    }

    &-icon {
      position: absolute;
      right: 0;
      top: 21px;

      @include media-breakpoint-up(md) {
        top: 36px;
      }
    }
  }

  &--text {
    margin: 0;
    padding: 0;
    font-size: 14px;

    @include media-breakpoint-up(md) {
      font-size: 16px;
    }

    p:first-of-type {
      padding-top: 16px;

      @include media-breakpoint-up(md) {
        padding-top: 28px;
      }
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}
