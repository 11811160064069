

.header {
  .nav-desktop .nav-link:not(.show-as-button) {
    @include underline-hover;
  }

  &--spacer {
    transition: all 0.25s ease;
  }

  .nav-desktop .nav-link {
    transition: 0.3s all ease;
    font-size: 16px;
    cursor: pointer;

    &:not(.show-as-button).active::before {
      width: 100%;
    }

    .nav-link--text {
      pointer-events: none;
    }
  }

  .nav-desktop .show-as-button {
    color: $black !important;
    background-color: $white;
    border-color: $white;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 70px;
    border-width: 2px;
    border-style: solid;
    transition: all 0.2s ease;

    &:hover, &:active {
      opacity: 0.8;
    }
  }

  &,
  .light-background & {
    .nav-desktop .nav-link {
      color: $black;
    }

    #hamburger span {
      background-color: $black;
    }

    .header--logo--white {
      display: none;
    }

    .header--logo--black {
      display: inline;
    }
  }

  .dark-background & {
    .nav-desktop .nav-link {
      color: $white;
    }

    #hamburger span {
      background-color: $white;
    }

    .header--logo--white {
      display: inline;
    }

    .header--logo--black {
      display: none;
    }
  }

  &--narrow {
    background-color: $white;
  }

  &--narrow,
  .light-background &--narrow,
  .light-background & {
    .nav-desktop .nav-link {
      color: $dark;
    }

    #hamburger span {
      background-color: $dark;
    }

    .header--logo--white {
      display: none;
    }

    .header--logo--black {
      display: inline;
    }
  }

  &.hamburger-open {
    .header--logo--white {
      display: inline;
    }

    .header--logo--black {
      display: none;
    }

    .header--logo {
      z-index: 10;
      position: absolute;
      margin-top: 5px;
    }
  }
}


.nav-desktop {
  padding-top: 18px;

  ul {
    li {
      display: inline-block;

      ul {
        // Disable for now
        display: none;
      }
    }
  }
}

.header--logo {
  height: 17px;
  width: 111px;

  &--container {
    display: inline-block;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-right: 20px;
  }

  a {
    display: inline-block;
  }
}

//  HAMBURGER  –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
$hamburger-color: $dark;
$hamburger-color-clicked: white;
$hamburger-size-width: 30px;
$hamburger-size-height: 30px;
//  HAMBURGER MENU  –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
$hamburger-menu-backgound-color: rgba($dark, 0.95);
$hamburger-menu-link-color: $primary;

// HAMBURGER  ––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
#hamburger {
  display: block;
  position: relative;
  top: 16px;
  z-index: 100;
  width: $hamburger-size-width;
  height: $hamburger-size-height;

  span {
    // Hamburger bars
    display: block;
    position: absolute;
    width: 100%;
    right: 0;
    height: 1px;
    background-color: $hamburger-color;
    transform: rotate(0deg);
    transition: all 0.15s ease;

    // bar positions

    &:nth-child(1) {
      top: 12px;
    }

    &:nth-child(2) {
      top: 18px;
      width: 50%;

    }
  }

  &.clicked {
    top: 20px;

    span {
      background-color: $hamburger-color-clicked;

      &:nth-child(1) {
        transform: rotate(-45deg);
        top: 10px;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
        top: 10px;
        width: 100%;
      }
    }
  }
}

.header.hamburger-open #hamburger {

  &,
  &.clicked {
    span {
      background-color: $white;
    }
  }
}

// HAMBURGER MENU ––––––––––––––––––––––––––––––––––––––––––––
.force-top {
  top: 0 !important; // forces header to top, overwriting reactive-nav.
  background-color: transparent;
}

#hamburger-menu {
  @include ease;

  background-color: $hamburger-menu-backgound-color;
  visibility: hidden;
  opacity: 0; // to ensure smooth transition
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 150px;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .nav-link-item {
    text-align: right;
  }

  .nav-link {
    color: $white;
    font-size: 36px;
    display: inline-block;
    padding-bottom: 5px;
    line-height: 1.2;
    @include underline-hover;

    &.active {
      border-bottom: 2px solid $white;
    }
  }

  ul ul {
    // hide for now.
    display: none;
  }

  a {
    color: $white;
  }

  .hamburger-nav {
    &-wrapper {
      height: 100%;
      width: 100%;
      overflow-y: scroll;
    }
  }
}
