.footer {
  color: $text-black;
  font-size: 14px;

  .nav-link {
    @include underline-hover;
  }

  .dark-background & {
    color: $white;
  }

  a {
    color: $text-black;

    .dark-background & {
      color: $white;
    }
  }

  &--description {
    font-size: calc(22px + 8 * ((100vw - 320px) / 2180));
    color: $text-black;
    line-height: 1.3em;
    font-weight: 400;

    p {
      margin-bottom: 48px;
    }

    .dark-background & {
      color: $white;
    }
  }

  &--seo-text {
    color: $black-50;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;

    p:last-child {
      margin-bottom: 0;

      @include media-breakpoint-down(md) {
        margin-bottom: 48px;
      }
    }

    .dark-background & {
      color: $white-50;
    }
  }

  .nav {
    &-link {
      font-weight: 500;
    }

    &-link-item {
      margin-bottom: 5px;
      white-space: nowrap;
    }

    &-parent > .nav-link {
      cursor: pointer;

      &-wrapper {
        $default-rotate: rotate(45deg);
        $expanded-rotate: rotate(-135deg);
        $hover-scale: scale(1.5);

        .chevron {
          @include ease;

          position: absolute;
          content: '';
          border: 1px solid $black-50;
          width: 4px;
          height: 4px;
          border-left: 0;
          border-top: 0;
          transform: $default-rotate;
          top: 9px;

          .dark-background & {
            border: 1px solid $white-50;
            border-left: 0;
            border-top: 0;
          }
        }

        .chevron-toggle {
          cursor: pointer;
          position: relative;
          padding: 3px 15px 4px 8px;
          height: 20px;
          appearance: none;
          border: none;
          background-color: transparent;

          &:hover {
            .chevron {
              transform: $hover-scale $default-rotate;
            }
          }

          &[aria-expanded="true"] {
            .chevron {
              top: 9px;
              transform: $expanded-rotate;
            }

            &:hover {
              .chevron {
                transform: $hover-scale $expanded-rotate;
              }
            }

          }

        }

      }
    }

    &-children {
      padding-left: 1rem;

      .nav-child:first-child {
        padding-top: 0.4rem;
      }
    }
  }

  &--column {
    break-inside: avoid-column;

    &-2,
    &-3 {
      .nav-link-item .nav-link {
        font-weight: 400;
        color: $black-50;

        &:before {
          background-color: $black-50;
        }

        .dark-background & {
          color: $white-50;

          &:before {
            background-color: $white-50;
          }
        }
      }

      .nav-child {
        padding-bottom: 0.4rem;

        .nav-link {
          color: $black-50;

          &:before {
            background-color: $black-50;
          }

          .dark-background & {
            color: $white-50;

            &:before {
              background-color: $white-50;
            }
          }
        }

        &:last-child {
          padding-bottom: 0;
        }
      }

      .nav-link-item:first-child {
        margin-bottom: 0.75rem;

        .nav-link {
          font-weight: 500;
          color: $text-black;

          &:before {
            background-color: $text-black;
          }

          .dark-background & {
            color: $white;
          }
        }
      }
    }
  }

  &--columns {
    @include media-breakpoint-down(md) {
      column-count: 2;
    }

    @include media-breakpoint-down(xs) {
      column-count: 1;
    }
  }

  &--column-full {
    @include media-breakpoint-down(md) {
      column-span: all;
    }
  }

  .social-dark {
    display: block;

    .dark-background & {
      display: none;
    }
  }

  .social-light {
    display: none;

    .dark-background & {
      display: block;
    }
  }

  &--postfooter--link:nth-child(n + 2) {
    @include underline-hover;

    color: $black-50;

    .dark-background & {
      color: $white-50;
    }
  }
}
