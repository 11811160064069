.block--hero-text {
  color: $text-black;

  &,
  &.margin-default {
    margin: 0 0 $block-margin-mobile-default;

    @include media-breakpoint-up(md) {
      margin: 0 0 $block-margin-default;
    }
  }

  &.margin-tall {
    margin: 0 0 $block-margin-mobile-tall;

    @include media-breakpoint-up(md) {
      margin: 0 0 $block-margin-tall;
    }
  }

  &.margin-short {
    margin: 0 0 $block-margin-mobile-short;

    @include media-breakpoint-up(md) {
      margin: 0 0 $block-margin-short;
    }
  }

  &:first-child {
    &,
    &.margin-default {
      margin: 0 0 $block-margin-mobile-default + $hero-text-margin-adjust;

      @include media-breakpoint-up(md) {
        margin: 0 0 $block-margin-default + $hero-text-margin-adjust;
      }

      &:first-child {
        padding-top: $block-margin-mobile-default + $hero-text-margin-adjust;

        @include media-breakpoint-up(md) {
          padding-top: $block-margin-default + $hero-text-margin-adjust;
        }
      }
    }

    &.margin-tall {
      margin: 0 0 $block-margin-mobile-tall + $hero-text-margin-adjust;

      @include media-breakpoint-up(md) {
        margin: 0 0 $block-margin-tall + $hero-text-margin-adjust;
      }

      &:first-child {
        padding-top: $block-margin-mobile-tall + $hero-text-margin-adjust;

        @include media-breakpoint-up(md) {
          padding-top: $block-margin-tall + $hero-text-margin-adjust;
        }
      }
    }

    &.margin-short {
      margin: 0 0 $block-margin-mobile-short + $hero-text-margin-adjust;

      @include media-breakpoint-up(md) {
        margin: 0 0 $block-margin-short + $hero-text-margin-adjust;
      }

      &:first-child {
        padding-top: $block-margin-mobile-short + $hero-text-margin-adjust;

        @include media-breakpoint-up(md) {
          padding-top: $block-margin-short + $hero-text-margin-adjust;
        }
      }
    }
  }

  &--title h2 {
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: -0.01em;
    margin-bottom: 33px;
  }
  &--text {
    @extend h3;

    font-weight: 300;
  }
}