.block--v3--link-cards {
  $prefix: &;

  &--heading {
    margin-bottom: 32px;
    @include fluid-type(20px, 30px);
    font-style: normal;
    font-weight: 400;
    line-height: 1.4em;
    margin-bottom: 0.6em;
  }

  &--items-wrapper {
    width: calc(100% + 25px);
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
      width: 0 !important
    }

    @include media-breakpoint-up(sm) {
      width: calc(100% + 50px);
    }

    @include media-breakpoint-up(lg) {
      max-width: 100%;
      margin-left: 0;
    }
  }

  &--items {
    min-width: 993px;

    @include media-breakpoint-up(sm) {
      min-width: 1018px;
    }

    @include media-breakpoint-up(md) {
      min-width: 1078px;
    }

    @include media-breakpoint-up(lg) {
      min-width: 0;
    }
  }

  &--item {
    margin-right: 20px;
    width: calc(25% - 22px);
    min-width: 227px;

    @include media-breakpoint-up(sm) {
      width: calc(25% - 27.5px);
    }

    @include media-breakpoint-up(md) {
      margin-right: 40px;
      width: calc(25% - 42.5px);
    }

    @include media-breakpoint-up(lg) {
      min-width: 0;
      width: calc(25% - 30px);
    }

    &:nth-child(4n) {
      margin-right: 25px;

      @include media-breakpoint-up(sm) {
        margin-right: 50px;
      }

      @include media-breakpoint-up(lg) {
        margin-right: 0;
      }
    }
  }

  &--image {
    display: inline-block;
    overflow: hidden;

    img {
      @include ease;
      @include zoom-hover;
    }
  }

  &--arrow {
    @include ease;

    margin-top: 10px;
    margin-right: 10px;
  }

  &--title {
    font-weight: 400;
    line-height: 1.4em;
    margin-top: 20px;
    font-size: 16px;

    @include media-breakpoint-up(md) {
      margin-top: 24px;
      font-size: 20px;
    }
  }

  &--link {
    &:hover {
      #{$prefix}--arrow {
        @extend .arrow-pseudo--move--rotate-to-right;
      }
    }
  }
}
