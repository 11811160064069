.block--v3--link-stack {
  $prefix: &;

  &--heading {
    @include fluid-type(22px, 30px);
  }

  &--title {
    margin-bottom: 0;
    font-size: 16px;

    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  }

  a,
  p {
    color: $text-black;

    .dark-background & {
      color: $white;
    }
  }

  a {
    @include ease;

    &:hover {
      color: $text-black;
      opacity: 0.8;
    }

    .dark-background & {
      &:hover {
        color: $white;
      }
    }
  }

  &--item {
    display: block;
    border-top: 1px solid $social-border-grey;
    position: relative;
    padding-bottom: 16px;
    padding-top: 16px;

    @include media-breakpoint-up(md) {
      padding-top: 28px;
      padding-bottom: 26px;
    }
  }

  &--arrow {
    @include ease;

    margin-top: 8px;
    margin-right: 10px;
  }

  &--link {
    text-decoration: none;

    &:hover {
      .arrow-pseudo--down-right {
        @extend .arrow-pseudo--move--rotate-to-right;
      }

      .arrow-pseudo--down {
        @extend .arrow-pseudo--move--nudge-down;
      }
    }
  }
}
