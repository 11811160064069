.block--v3--general {
  @include ease;

  h1,
  .h1 {
    @include fluid-type(32px, 60px);
    font-weight: 400;
    font-style: normal;
    line-height: 1.2em;
  }

  &--wrapper {
    // Margins
    &.margin-default {
      margin: 0 0 $block-margin-mobile-default;

      @include media-breakpoint-up(md) {
        margin: 0 0 $block-margin-default;
      }
    }

    &.margin-tall {
      margin: 0 0 $block-margin-mobile-tall;

      @include media-breakpoint-up(md) {
        margin: 0 0 $block-margin-tall;
      }
    }

    &.margin-short {
      margin: 0 0 $block-margin-mobile-short;

      @include media-breakpoint-up(md) {
        margin: 0 0 $block-margin-short;
      }
    }
  }
}
