.small-button {
  @include ease;

  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  padding: 5px 16px;
  color: $black;
  border: 1px solid $black;
  background-color: $white;
  border-radius: 16px;

  &:hover {
    color: $white;
    border: 1px solid $black;
    background-color: $black;
  }


  &--solid {
    color: $white;
    border: 1px solid $black;
    background-color: $black;

    &:hover {
      color: $black;
      border: 1px solid $black;
      background-color: $white;
    }
  }
}
