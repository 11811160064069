.block--v3--carousel-clients {
  $prefix: &;
  width: 100%;
  overflow: hidden;

  .carousel {
    min-width: 380px;
  }

  &--card-wrapper {
    width: 100%;
    padding-top: 100%;
    position: relative;
  }

  &--image-wrapper,
  &--description {
    @include ease;
    position: absolute;
    pointer-events: none;
  }

  &--image-wrapper {
    width: 100%;
    top: 50%;
    left: 50%;
    justify-content: center;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: transform .3s cubic-bezier(.3, 0, .3, 1), opacity .3s linear;
  }

  &--description {
    top: 0;
    padding: 10%;
    margin-bottom: 15%;
    opacity: 0;
    width: 100%;
  }

  &--card {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 38px;
    background-color: $tile-grey;

    &.centered {
      justify-content: center;
      align-items: center;
    }

    .more {
      @include ease;
      opacity: 1;
    }

    .hide {
      @include ease;
      opacity: 0;
    }

    &.active {
      #{$prefix}--image-wrapper {
        opacity: 0;
        -webkit-transform: translate(-50%, -40%);
        transform: translate(-50%, -40%);
      }

      #{$prefix}--description {
        margin-bottom: 0;
        opacity: 1;
      }

      .more {
        opacity: 0;
      }

      .hide {
        opacity: 1;
      }
    }
  }

  &--image {
    @include ease;
    width: auto;
    max-width: calc(100% - 100px);
  }

  &--text {
    bottom: 8%;
    position: absolute;
    width: 100%;
    padding: 0 10%;
  }

  &--name {
    font-size: 1rem;
    line-height: 1.1875;
    color: $text-grey-light;
  }

  &--toggle {
    position: relative;
  }

  &--more {
    font-size: 1rem;
    line-height: 1.1875;
  }

  &--hide {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1rem;
    line-height: 1.1875;

    @include media-breakpoint-up(lg) {
      right: 0;
    }
  }
}
