.info-panel {
  .toggle-symbol {
    position: relative;
    float: right;
    width: 17px;
    height: 17px;

    &:before{
      content: " ";
      position: absolute;
      background-color: $dark;
      display: block;
      height: 100%;
      width: 1px;
      left: 8px;
      z-index: 9;
    }

    &:after{
      content: " ";
      position: absolute;
      background-color: $dark;
      display: block;
      height: 1px;
      width: 100%;
      top: 8px;
      z-index: 9;
    }
  }

  &--toggle.active .toggle-symbol:before {
    opacity: 0;
  }
}
