.block--accordion {
  &--item {
    margin-bottom: 0.5rem;

    &--heading {
      margin-bottom: 0;
    }

    a,
    p {
      color: $text-black;

      .dark-background & {
        color: $white;
      }
    }

    a {
      @include ease;

      &:hover {
        color: $text-black;
        opacity: 0.8;
      }

      .dark-background & {
        &:hover {
          color: $white;
        }
      }
    }

    &--toggle {
      display: block;
      padding-top: 0.8rem;
      border-top: 1px solid $black;
      margin-bottom: 1rem;
      position: relative;

      .dark-background & {
        border-top: 1px solid $white;
      }

      &-icon {
        @include accordion-toggle-base;
        @include accordion-toggle-plus;
        @include ease;

        position: absolute;
        top: 1.5rem;
        right: 0;
        opacity: 0.5;

        .block--accordion--toggle :hover & {
          opacity: 1;
        }
      }

      &[aria-expanded="true"] .block--accordion--item--toggle-icon {
        @include accordion-toggle-minus;
      }
    }

    &--text {
      margin: 0;
      padding: 0;

      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
